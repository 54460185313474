var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c("h5", { staticClass: "mb-2" }, [_vm._v(" Suggested Pages ")]),
      _vm._l(_vm.pagesData, function (data, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "d-flex justify-content-start align-items-center mt-1",
          },
          [
            _c(
              "div",
              { staticClass: "mr-1" },
              [_c("b-avatar", { attrs: { size: "40", src: data.avatar } })],
              1
            ),
            _c("div", { staticClass: "profile-user-info" }, [
              _c("h6", { staticClass: "mb-0" }, [
                _vm._v(" " + _vm._s(data.username) + " "),
              ]),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(data.subtitle)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "profile-star ml-auto" },
              [
                _c("feather-icon", {
                  class: data.favorite ? "profile-favorite" : "",
                  attrs: { icon: "StarIcon", size: "18" },
                }),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }