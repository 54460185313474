var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "profile-header mb-2",
      attrs: {
        "img-src": _vm.headerData.coverImg,
        "img-top": "",
        alt: "cover photo",
        "body-class": "p-0",
      },
    },
    [
      _c("div", { staticClass: "position-relative" }, [
        _c(
          "div",
          { staticClass: "profile-img-container d-flex align-items-center" },
          [
            _c(
              "div",
              { staticClass: "profile-img" },
              [
                _c("b-img", {
                  attrs: {
                    src: _vm.headerData.avatar,
                    rounded: "",
                    fluid: "",
                    alt: "profile photo",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "profile-title ml-3" }, [
              _c("h2", { staticClass: "text-white" }, [
                _vm._v(" " + _vm._s(_vm.headerData.username) + " "),
              ]),
              _c("p", { staticClass: "text-white" }, [
                _vm._v(" " + _vm._s(_vm.headerData.designation) + " "),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "profile-header-nav" },
        [
          _c(
            "b-navbar",
            { attrs: { toggleable: "md", type: "light" } },
            [
              _c(
                "b-navbar-toggle",
                {
                  staticClass: "ml-auto",
                  attrs: { target: "nav-text-collapse" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "AlignJustifyIcon", size: "21" },
                  }),
                ],
                1
              ),
              _c(
                "b-collapse",
                { attrs: { id: "nav-text-collapse", "is-nav": "" } },
                [
                  _c("b-tabs", {
                    staticClass: "profile-tabs mt-1 mt-md-0",
                    attrs: { pills: "", "nav-class": "mb-0" },
                    scopedSlots: _vm._u([
                      {
                        key: "tabs-start",
                        fn: function () {
                          return [
                            _c(
                              "b-nav-item",
                              {
                                staticClass: "font-weight-bold",
                                attrs: { role: "presentation", active: "" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-md-block" },
                                  [_vm._v("Feed")]
                                ),
                                _c("feather-icon", {
                                  staticClass: "d-block d-md-none",
                                  attrs: { icon: "RssIcon" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-nav-item",
                              {
                                staticClass: "font-weight-bold",
                                attrs: { role: "presentation" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-md-block" },
                                  [_vm._v("About")]
                                ),
                                _c("feather-icon", {
                                  staticClass: "d-block d-md-none",
                                  attrs: { icon: "InfoIcon" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-nav-item",
                              {
                                staticClass: "font-weight-bold",
                                attrs: { role: "presentation" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-md-block" },
                                  [_vm._v("Photos")]
                                ),
                                _c("feather-icon", {
                                  staticClass: "d-block d-md-none",
                                  attrs: { icon: "ImageIcon" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-nav-item",
                              {
                                staticClass: "font-weight-bold",
                                attrs: { role: "presentation" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-md-block" },
                                  [_vm._v("Friends")]
                                ),
                                _c("feather-icon", {
                                  staticClass: "d-block d-md-none",
                                  attrs: { icon: "UsersIcon" },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "tabs-end",
                        fn: function () {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "ml-auto",
                                attrs: { variant: "primary" },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "d-block d-md-none",
                                  attrs: { icon: "EditIcon" },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-weight-bold d-none d-md-block",
                                  },
                                  [_vm._v("Edit")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }