var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c("h5", [_vm._v("Suggestions")]),
      _vm._l(_vm.suggestions, function (data, index) {
        return _c(
          "div",
          {
            key: data.avatar,
            staticClass: "d-flex justify-content-start align-items-center",
            class: index == 0 ? "mt-2" : "mt-1",
          },
          [
            _c("b-avatar", {
              staticClass: "mr-50",
              attrs: { src: data.avatar, size: "40" },
            }),
            _c("div", { staticClass: "user-page-info" }, [
              _c("h6", { staticClass: "mb-0" }, [
                _vm._v(" " + _vm._s(data.name) + " "),
              ]),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(data.mutualFriend)),
              ]),
            ]),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "ripple",
                    rawName: "v-ripple.400",
                    value: "rgba(186, 191, 199, 0.15)",
                    expression: "'rgba(186, 191, 199, 0.15)'",
                    modifiers: { 400: true },
                  },
                ],
                staticClass: "btn-icon ml-auto",
                attrs: { variant: "primary", size: "sm" },
              },
              [_c("feather-icon", { attrs: { icon: "UserPlusIcon" } })],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }